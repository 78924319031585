import api from './api-config'
import { BookData, ResponseStatus, Token, UserData } from '../types'

export default class UserApi {
  static api = api
  static async login(email: string, password: string): Promise<{ message: string; user: UserData } & Token> {
    return (await api.post('/user/login/', { email, password })).data
  }

  static async oauth2Login(path: string): Promise<any> {
    return (await api.get(`/user${path}/`)).data
  }

  static async register(userData: UserData): Promise<{ user: UserData } & Token> {
    return (await api.post('/user/register/', userData)).data
  }

  static async createAccountVerificationLink(): Promise<ResponseStatus> {
    return (await api.post('/user/account-verify/')).data
  }

  static async accountVerify(emailVerificationToken: string): Promise<{ user: UserData } & Token> {
    return (await api.get(`/user/account-verify/${emailVerificationToken}/token/`)).data
  }

  static async createRecoveryLink(email: string): Promise<ResponseStatus> {
    return (await api.post('/user/account-recovery-token/', { email })).data
  }

  static async getRecoveryLinkToken(recoveryLinkId: string): Promise<Token> {
    return (await api.get(`/user/account-recovery-token/${recoveryLinkId}/token/`)).data
  }

  static async updatePasswordFromRecoveryLinkAndLogin(
    recoveryLinkId: string,
    passwordData: { token: string; password: string }
  ): Promise<{ user: UserData } & Token> {
    return (await api.post(`/user/account-recovery-token/${recoveryLinkId}/update-password/`, passwordData)).data
  }

  static async update<T extends Partial<UserData>>(userData: T, profilePicture?: File): Promise<T> {
    if (!profilePicture) {
      return (await api.put(`/user/${userData._id}/`, userData)).data
    }

    const formData = new FormData()
    formData.append('profilePicture', profilePicture)
    for (const [key, value] of Object.entries(userData)) {
      formData.append(key, value as any)
    }

    return (
      await api.put(`/user/${userData._id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    ).data
  }

  static async listUsers(): Promise<UserData[]> {
    return (await api.get('/user/')).data
  }

  static async getUserById(id: string): Promise<UserData> {
    return (await api.get(`/user/${id}/`)).data
  }

  static async getLibraryBookList(): Promise<BookData[]> {
    return (await api.get('/user/library/')).data
  }

  static async getBookReadHistory(pageNumber: number): Promise<BookData[]> {
    return (await api.get(`/user/book/read-history/?pageNumber=${pageNumber}`)).data
  }
  static async addBookToLibrary(bookId: string): Promise<any> {
    return (await api.post(`/user/library/${bookId}/`)).data
  }

  static async removeBookFromLibrary(bookId: string): Promise<any> {
    return (await api.delete(`/user/library/${bookId}/`)).data
  }

  static async removeBookFromReadHistory(bookId: string): Promise<any> {
    return (await api.delete(`/user/book/read-history/${bookId}/`)).data
  }

  static async collectLead(email: string, targetId: string, offerId: string): Promise<any> {
    return (await api.post(`/user/lead/collect/?email=${email}&targetId=${targetId}&offerId=${offerId}`)).data
  }

  static async getNextOffer(targetId: string): Promise<any> {
    return (await api.get(`/user/lead/offer/?targetId=${targetId}`)).data
  }
}
