// ** React Imports
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiCard, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import EyeOutline from 'mdi-material-ui/EyeOutline'

// ** Configs
import themeConfig from 'src/configs/themeConfig'

// ** Layout Import

// ** Demo Imports
import { Facebook } from '@mui/icons-material'
import { Divider, FormHelperText, Stack } from '@mui/material'
import { getCookie, setCookie } from 'cookies-next'
import jwt, { JwtPayload } from 'jsonwebtoken'
import { Apple } from 'mdi-material-ui'
import Head from 'next/head'
import useLocalStorage from 'src/@core/hooks/useLocalStorage'
import UserApi from 'src/@core/utils/api/user-api'
import AppLogo from 'src/views/logos/AppLogo'
import GoogleIcon from 'src/views/logos/Google'

interface State {
  email: string
  password: string
  showPassword: boolean
  email_error: boolean
  email_error_message?: string
  password_error: boolean
  password_error_message?: string
  login_error: boolean
  login_error_message: string
}

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const LoginPage = () => {
  // ** State
  const [values, setValues] = useState<State>({
    email: '',
    password: '',
    showPassword: false,
    email_error: false,
    password_error: false,
    login_error: false,
    login_error_message: ''
  })

  const [, setUser] = useLocalStorage('user')

  // ** Hook
  const router = useRouter()

  const token = getCookie('token') as string
  useEffect(() => {
    if (token) {
      try {
        const { exp } = jwt.decode(token) as JwtPayload
        if (exp && exp >= Date.now() / 1000) {
          router.push('/user/library/')
        }
      } catch {
        console.warn('invalid token stored in the cookies')
      }
    }
  }, [token, router])

  const requiredFieldText = 'This field is required.'

  const doLogin = async () => {
    const errors: Partial<State> = {}

    if (!values.email) {
      errors.email_error = true
      errors.email_error_message = requiredFieldText
    }
    if (!values.password) {
      errors.password_error = true
      errors.password_error_message = requiredFieldText
    }
    if (errors.email_error || errors.password_error) {
      setValues({ ...values, ...errors })

      return
    }

    setValues({ ...values, login_error: false })

    try {
      const response = await UserApi.login(values.email, values.password)
      if (response.token) {
        setCookie('token', response.token)
        setUser(response.user)
        router.push('/')

        return
      } else {
        setValues({
          ...values,
          login_error: true,
          login_error_message: 'There was an error in our server. Please, try again...'
        })
      }
    } catch (e: any) {
      setValues({
        ...values,
        login_error: true,
        login_error_message:
          e?.response?.status === 403
            ? 'Invalid credentials. Please, try again...'
            : 'There was an error in our server. Please, try again...'
      })
    }
  }

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      [`${prop}_error`]: false,
      [`${prop}_error_message`]: '',
      login_error: false
    })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      <Head>
        <title key={'title'}>{`Login`}</title>
      </Head>
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
              <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AppLogo />
                <Typography
                  variant='h6'
                  sx={{
                    ml: 3,
                    lineHeight: 1,
                    fontSize: '2.5rem !important',
                    fontFamily: 'Island Moments'
                  }}
                >
                  {themeConfig.templateName}
                </Typography>
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                  Welcome to {themeConfig.templateName}! 👋🏻
                </Typography>
                <Typography variant='body2'>Please sign-in to your account</Typography>
              </Box>
              <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()}>
                <TextField
                  autoFocus
                  fullWidth
                  id='email'
                  label='Email'
                  sx={{ marginBottom: 4 }}
                  value={values.email}
                  onChange={handleChange('email')}
                  error={values.email_error}
                  helperText={values.email_error_message}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor='auth-login-password'>Password</InputLabel>
                  <OutlinedInput
                    label='Password'
                    value={values.password}
                    error={values.password_error}
                    id='auth-login-password'
                    onChange={handleChange('password')}
                    type={values.showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          aria-label='toggle password visibility'
                        >
                          {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {values.password_error && (
                    <FormHelperText error id='password-error'>
                      {values.password_error_message}
                    </FormHelperText>
                  )}
                </FormControl>
                {values.login_error && (
                  <FormHelperText error id='password-error'>
                    {values.login_error_message}
                  </FormHelperText>
                )}
                <Box
                  sx={{
                    mb: 4,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label='Remember Me' />
                  <LinkStyled href='/forgot-password/'>Forgot Password?</LinkStyled>
                </Box>
                <Button fullWidth size='large' variant='contained' sx={{ marginBottom: 7 }} onClick={doLogin}>
                  Login
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <Typography variant='body2' sx={{ marginRight: 2 }}>
                    New on our platform?
                  </Typography>
                  <Typography variant='body2'>
                    <LinkStyled href='/register/'>Create an account</LinkStyled>
                  </Typography>
                </Box>
                <Divider>or</Divider>
                <Stack spacing={2} direction={'row'} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <IconButton href='/api/oauth2/login/federated/google/' sx={{ backgroundColor: 'white' }}>
                    <GoogleIcon />
                  </IconButton>
                  <IconButton href='/api/oauth2/login/federated/facebook/' sx={{ backgroundColor: 'white' }}>
                    <Facebook sx={{ color: '#1da1f2' }}></Facebook>
                  </IconButton>
                  <IconButton href='/api/oauth2/login/federated/apple/' sx={{ backgroundColor: 'white' }}>
                    <Apple sx={{ color: 'black' }}></Apple>
                  </IconButton>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  )
}

export default LoginPage
