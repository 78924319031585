import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { getCookie } from 'cookies-next'

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_DOMAIN}${process.env.NEXT_PUBLIC_API_URL}`
})

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = getCookie('token')
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error: AxiosError) => Promise.reject(error)
)

export default api
